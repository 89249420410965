<template>
  <v-card class="pa-6">
    <v-list v-if="!loading">
      <v-list-item
        v-for="origen in origens"
        :key="origen.id"
        @click="openDialog(origen)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ origen.descricao }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-if="vueEditOrigen">
          <v-icon>
            edit
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <Carregando v-else />
    <v-card-text v-if="!origens.length">
      {{ $tc("msg.nenhumaorigem") }}
    </v-card-text>

    <v-dialog v-model="dialog" max-width="350">
      <v-card :loading="atualizando" :disabled="atualizando">
        <v-card-title class="subtitle-1">
          {{ $tc("global.atualizar") }} {{ origen.descricao }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="origen.descricao"
            class="mt-4"
            :label="$tc('global.descricao')"
            dense
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="anchors" text @click="dialog = false">
            {{ $tc("botoes.cancelar") }}
          </v-btn>
          <v-btn color="anchors" text @click="updateOrigen(origen)">
            {{ $tc("global.atualizar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import OrigensApi from "@/services/api/origens.api.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaOrigens",
  data: () => ({
    loading: true,
    origens: [],
    origen: {},
    atualizando: false,
    dialog: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueEditOrigen() {
      return this.getAccess("vueOrigen", "editar");
    },
  },
  methods: {
    // goToCliente(item) {
    //   if (this.vueVerCliente) {
    //     this.$store.commit("UPDATE_DYNAMICCRUMB", item.nome);
    //     this.$router.push({
    //       name: "Cliente",
    //       params: { clienteId: item.id_cliente }
    //     });
    //   }
    // },
    openDialog(origen) {
      if (this.vueEditOrigen) {
        this.dialog = !this.dialog;
        this.origen = origen;
      }
    },
    updateOrigen(origen) {
      this.atualizando = true;
      this.$Progress.start();
      OrigensApi.putOrigens(origen.id, origen.descricao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.atualizadosucesso"));
            this.dialog = false;
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.atualizando = false;
        });
    },
    getorigens() {
      this.$Progress.start();
      this.loading = true;
      OrigensApi.getOrigens()
        .then((response) => {
          this.origens = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getorigens();
  },
};
</script>

<style scoped lang="scss"></style>
